<template>
    <div class="main">
        <div class="bg-wrp">
            <div class="color-face"></div>
        </div>
        <div class="login-wrp">
            <div class="login-inner">
                <h3>密码修改</h3>

                <div class="user input-wrp">
                    <span>
                        <img src="../assets/user.png" alt="" srcset="" />
                    </span>
                    <el-input type="text" placeholder="请输入账号名" v-model="name" disabled></el-input>
                </div>
                <div class="passwd input-wrp">
                    <span>
                        <img src="../assets/password.png" alt="" srcset="" />
                    </span>
                    <el-input placeholder="请输入新密码" v-model="password" show-password></el-input>
                </div>
                <el-button type="primary" @click="changePasswd">确定修改</el-button>
            </div>
        </div>
        <div class="copyright">版权所有 &copy; 温州微在线网络科技有限公司</div>
    </div>
    </template>
    
    <script>
    export default {
        data() {
            return {
                name: "",
                password: "",
            };
        },
        mounted() {
            let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
            this.name = userInfo.TravelArea_Name || userInfo.TravelAgency_Name;
        },
        methods: {
            changePasswd() {
    
                if (this.name == '' || this.password == '') {
                    this.$message('请填写完整的信息')
                    return false
                }
                let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
                let typeStr = 'areachangepassword'
                if(userInfo.hasOwnProperty('TravelAgency_Name')){
                    typeStr = 'changepassword'
                }
                this.ipost(
                    "/AdminTravel/login.ashx?type="+typeStr, {
                        // name: this.name,
                        password: this.password,
                    },
                    (res) => {
                        console.log("res:", res);
                        alert("密码修改成功,请重新登录");
                        localStorage.setItem("loginState", false);
                        localStorage.removeItem("userInfo");
                        this.$router.push({
                            name: "Login"
                        });
                    }
                );
            },
        },
    };
    </script>
    
    <style scoped>
    .main {
        min-width: 980px;
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #fdfdfd;
    }
    
    .bg-wrp {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url("../assets/office.jpg");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    
    .color-face {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.83);
    }
    
    .login-wrp {
        position: absolute;
        top: 37%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
    }
    
    .logo-png {
        display: block;
        width: 40%;
        margin: 20px auto;
    }
    
    .login-inner {
        width: 47%;
        min-width: 330px;
        margin: 0 auto;
        padding: 10px;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0 0 12px #ccc;
    }
    
    .login-inner h3 {
        text-align: center;
        color: #00a1e9;
    }
    
    .input-wrp {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px auto;
        /* border: 1px solid #efefef; */
        border-radius: 4px;
    }
    
    .input-wrp span {
        padding: 0 12px;
        /* border-right: 1px solid #eee; */
    }
    
    .input-wrp img {
        width: 16px;
    }
    
    .input-wrp input {
        box-sizing: border-box;
        padding-left: 10px;
        line-height: 31px;
        width: 90%;
    }
    
    .input-wrp input:focus {
        outline-color: #00a1e9;
    }
    
    .el-button {
        margin: 12px 1.5%;
        width: 97%;
    }
    
    .copyright {
        color: #999;
        line-height: 1.8;
        text-align: center;
        font-size: 11px;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
    }
    
    .tips {
        width: 47%;
        margin: 6px auto;
    }
    
    .tips a {
        font-size: 12px;
        color: #666;
    }
    </style>
    