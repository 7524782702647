<template>
  <div id="app">
    <el-container class="mainCont">
      <el-aside width="200px">
        <!-- Aside content -->
        <navs />
      </el-aside>
      <el-container>
        <el-header height="60px">
          <!-- Header content -->
          <toper />
        </el-header>
        <el-main height="100%">
          <!-- Main content -->
          <transition>
            <router-view></router-view>
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<style>
html,
body,
#app,
.mainCont {
  height: 100%;
}
.mainCont {
  width: 100%;
}
.el-header {
  background-color: #fff;
  display: flex;
  align-items: center;
}
.el-main {
  padding: 7px !important;
}
body {
  margin: 0;
  background-color: #f2f3f7;
}
</style>

<script>
import navs from "@/components/SubNavs";
import toper from "@/components/SubToper";

export default {
  components: { navs, toper },
};
</script>
