<template>
<div class="navs">
    <el-menu mode="vertical" default-active="1-1" background-color="#496cfc" text-color="#c5d3f8" active-text-color="#fff">
        <el-submenu index="1">
            <template slot="title">管理</template>
            <el-menu-item-group>
                <el-menu-item index="1-1">
                    <router-link to="/insurance/count"> 统计列表 </router-link>
                </el-menu-item>
                <el-menu-item index="1-2">
                    <router-link to="/insurance/delayList"> 延误险列表 </router-link>
                </el-menu-item>
            </el-menu-item-group>
        </el-submenu>
    </el-menu>
</div>
</template>

<script>
export default {};
</script>

<style scoped>
.navs {
    height: 100%;
    background-color: #496cfc;
    overflow: hidden;
    overflow-y: auto;
}

.el-menu-item a {
    color: rgb(197 211 248);
    display: block;
}

.el-menu-item.is-active a {
    color: #fff;
}

.logo {
    background-color: #496cfc;
    height: 60px;
    text-align: center;
}

.logo img {
    height: 45px;
    margin-top: 7.5px;
}
</style>
