<template>
  <div style="width: 100%">
    <el-row type="flex" justify="space-between" class="toper">
      <el-col :span="12" class="searchWrp">
        <!-- <el-input
          prefix-icon="el-icon-search"
          type="text"
          v-model="searchkey"
          clearable
          @change="searchFunc"
          placeholder="搜索"
        ></el-input> -->
      </el-col>

      <el-col :span="12" class="person">
        <div class="conctl">
          <span @click="loginOut">登出</span>
          <router-link to="/home/changePasswd">修改密码</router-link>
        </div>
        <div class="personInfo">
          <el-avatar
            icon="el-icon-user-solid"
            shape="circle"
            size="small"
          ></el-avatar>
          {{ name }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "匿名",
      searchkey: "",
    };
  },
  mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.name = userInfo.TravelAgency_Name;
  },
  methods: {
    searchFunc(evt) {
      console.log(evt);
    },
    loginOut() {
      this.ipost(
        "/AdminTravel/login.ashx?type=logout",
        {},
         (res) =>{
          console.log("res:", res);
          alert("登出成功!");
          sessionStorage.setItem("loginState", false);
          sessionStorage.removeItem("userInfo");
          this.$router.push({ name: "Login" });
        }
      );
    },
  },
};
</script>

<style scoped>
.el-input {
  width: 50%;
  min-width: 200px;
}
.searchWrp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.searchWrp i {
  padding: 0 12px;
}
.personInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-left: 1px solid #ddd;
}
.personInfo .el-avatar {
  margin: 0 6px;
}
.person,
.conctl {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.person img {
  height: 30px;
  padding: 0 12px;
}
.conctl {
  margin-right: 10px;
}
.conctl span,
.conctl a {
  color: #666;
  font-size: 13px;
  margin: 0 6px;
  cursor: pointer;
}
</style>
