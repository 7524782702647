import Vue from 'vue'
import VueRouter from 'vue-router'
import { ajax } from "jquery";
import Home from '../views/Home.vue'
import Agency from '../views/Agency.vue'
import Area from '../views/Area.vue'
import Insurance from "../views/Insurance.vue"
import ChangePassword from "../views/ChangePassword.vue"
import OrderCount from "../views/OrderCount.vue"

Vue.use(VueRouter)

const routes = [{
    path: '/home',
    name: 'Home',
    component: Home,
    children: [{
        path: 'changePasswd',
        name: 'ChangePasswd',
        component: () =>
            import('../views/ChangePasswd.vue')
    },
    {
        path: 'adminPolicySells',
        name: 'AdminPolicySells',
        component: () =>
            import('../views/AdminPolicySells.vue')
    },
    {
        path: 'travelProuductList',
        name: 'TravelProuductList',
        component: () =>
            import('../views/TravelProuductList.vue')
    },
    {
        path: 'cashCount',
        name: 'CashCount',
        component: () =>
            import('../views/CashCount.vue')
    },
    {
        path: 'orderCount',
        name: 'OrderCount',
        component: OrderCount,
    },
    {
        path: 'travelAreaList',
        name: 'TravelAreaList',
        component: () =>
            import('../views/TravelAreaList.vue')
    },
    {
        path: 'cashList',
        name: 'CashList',
        component: () =>
            import('../views/CashList.vue')
    },
    {
        path: 'travelAgencyList',
        name: 'TravelAgencyList',
        component: () =>
            import('../views/TravelAgencyList.vue')
    },
    {
        path: 'travelPolicyList',
        name: 'TravelPolicyList',
        component: () =>
            import('../views/TravelPolicyList.vue')
    },

    ]
},
{
    path: "/area",
    name: "Area",
    component: Area,
    children: [
        
        {
            path: 'travelList',
            name: 'TravelList',
            component: () =>
                import('../views/TravelList.vue')
        },
        {
            path: 'policyList',
            name: 'PolicyList',
            component: () =>
                import('../views/PolicyList.vue')
        },
        {
            path: 'changePassword',
            name: 'ChangePassword',
            component: ChangePassword
        },
        {
            path: 'cashCount',
            name: 'CashCountArea',
            component: () =>
                import('../views/AreaCashCount.vue')
        },{
            path: 'cashList',
            name: 'CashListArea',
            component: () =>
                import('../views/AreaCashList.vue')
        },
        {
            path: 'orderCount',
            name: 'AreaOrderCount',
            component: OrderCount,
        },
    ]
},
{
    path: "/agency",
    name: "Agency",
    component: Agency,
    children: [
        {
            path: 'policySells',
            name: 'PolicySells',
            component: () =>
                import('../views/PolicySells.vue')
        },
        {
            path: 'agencyPolicyList',
            name: 'AgencyPolicyList',
            component: () =>
                import('../views/AgencyPolicyList.vue')
        },
        {
            path: 'changePassword',
            name: 'agencyChangePassword',
            component: ChangePassword
        },]
},
{
    path:"/insurance",
    name:"Insurance",
    component:Insurance,
    children:[
        {
            path:"count",
            name:"Count",
            component: () =>
                import('../views/Count.vue')
        },
        {
            path:"delayList",
            name:"DelayList",
            component: () =>
                import('../views/DelayPolicyList.vue')
        },
        {
            path:"changePassword",
            name:"insuranceChangePassword",
            component:ChangePassword
        },
    ]
},

{
    path: '/',
    name: 'Login',
    component: () =>
        import('../views/Login.vue'),
    meta: {
        title:'微在线网络'
    }
}
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeResolve((to, from, next) => {
    let isAuthenticated = sessionStorage.getItem("loginState")
    let userType = sessionStorage.getItem("userType")
    let checkType = (userType == 0 ? 'checklogin':'checkarealogin')
    if (to.name !== 'Login') {
        ajax({
            url: "/AdminTravel/login.ashx?type="+checkType,
            method: "POST",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
            }
        })
            .then((res) => {
                if (res.result !== "success") {

                    console.log("状态异常:" + JSON.stringify(res))
                    next({ name: 'Login' })

                } else {
                    // console.log("res:", res);
                    // 登录时限内, 设置userInfo 并 更改登录状态值 loginState

                    sessionStorage.setItem("loginState", "true")
                    sessionStorage.setItem("companyid",res.companyid)
                    sessionStorage.setItem("companyname",res.companyname)

                    isAuthenticated = "true";
                    if (isAuthenticated != "true") {
                        // 登录时限失效时, 清空userInfo 并 更改登录状态值 loginState
                        sessionStorage.setItem("loginState", false)
                        isAuthenticated = "false";
                        sessionStorage.removeItem("userInfo")

                        alert('登录时效已过期,请重新登录');
                        next({ name: 'Login' })
                    }
                    next()


                }

            })
            .catch((err) => {
                console.log("网络异常:", JSON.stringify(err))
            });
    } else next()
})


export default router