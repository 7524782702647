<template>
<div class="navs">
    <el-menu mode="vertical" default-active="1" background-color="#496cfc" text-color="#c5d3f8" active-text-color="#fff">
        <el-submenu index="1">
            <template slot="title">保险销售</template>
            <el-menu-item-group>
                <el-menu-item index="1-1">
                    <router-link to="/agency/policySells"> 销售保单 </router-link>
                </el-menu-item>
            </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3">
            <template slot="title">查询统计</template>
            <el-menu-item-group>
                <el-menu-item index="3-1">
                    <router-link to="/agency/agencyPolicyList"> 查询保单 </router-link>
                </el-menu-item>
            </el-menu-item-group>
        </el-submenu>
    </el-menu>
</div>
</template>

<script>
export default {};
</script>

<style scoped>
.navs {
    height: 100%;
    background-color: #496cfc;
    overflow: hidden;
    overflow-y: auto;
}

.el-menu-item a {
    color: rgb(197 211 248);
    display: block;
}

.el-menu-item.is-active a {
    color: #fff;
}

.logo {
    background-color: #496cfc;
    height: 60px;
    text-align: center;
}

.logo img {
    height: 45px;
    margin-top: 7.5px;
}
</style>
